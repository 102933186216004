import React from 'react'
import styled from 'styled-components'

import Link from '~/components/Link'
import slugify from '~/utils/slugify'
import styles from '~/utils/styles'
import useI18n, { BY_PREFIX } from '~/utils/useI18N'

const AuthorLink = ({ author, white = false }) => {
  return (
    <>
      {useI18n(BY_PREFIX)}
      <StyledLink to={`/author/${slugify(author)}`} white={white}>
        {author}
      </StyledLink>
    </>
  )
}

export default AuthorLink

const StyledLink = styled(({ white, ...props }) => <Link {...props} />)`
  border-bottom: 1px solid currentColor;

  ${({ theme: { colors }, white }) => `
    color: ${white ? colors.white : colors.gray};
    
    &:focus,
    &:hover {
      color: ${white ? styles.mixColor(colors.white, -0.2) : colors.darkPink};
    }
  `};
`
