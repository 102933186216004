import React from 'react'
import Helmet from 'react-helmet'

import { DEFAULT_LANG } from '~/components/LangContext'
import useBlogMetadata from '~/staticQueries/useBlogMetadata'

const Seo = ({ lang, link, meta, blogMetadata }) => {
  const {
    site: { siteMetadata },
  } = useBlogMetadata()
  const metadata = {
    ...siteMetadata,
    ...blogMetadata,
  }
  const { title, author, description } = metadata

  return (
    <Helmet
      title={title}
      titleTemplate={`%s | ${siteMetadata.title}`}
      htmlAttributes={{ lang }}
      meta={[
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'twitter:card',
          content: 'summary',
        },
        {
          name: 'twitter:creator',
          content: author,
        },
        {
          name: 'twitter:title',
          content: title,
        },
        {
          name: 'twitter:description',
          content: description,
        },
      ].concat(meta)}
      link={[
        {
          rel: 'stylesheet',
          href:
            'https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;700;800;900&family=Roboto:wght@100;300;400;500;700;900&display=swap',
        },
        {
          rel: 'stylesheet',
          href: 'https://fonts.googleapis.com/icon?family=Material+Icons',
        },
      ].concat(link)}
    />
  )
}

Seo.defaultProps = {
  lang: DEFAULT_LANG,
  meta: [],
  link: [],
  blogMetadata: {},
}

export default Seo
