import React, { Fragment, useMemo } from 'react'
import styled from 'styled-components'

import Container from '~/components/Container'
import { useLangContext } from '~/components/LangContext'
import Link from '~/components/Link'
import styles from '~/utils/styles'
import useI18n, { useI18nArray } from '~/utils/useI18N'

const TranslatedOptions = ({
  availableLanguages: availableLanguagesProp,
  descriptionKey,
  includeCurrentLang = false,
  marginTop,
  replaceKey,
  replaceValue,
  showContainer,
}) => {
  const currentLang = useLangContext()
  const availableLanguages = useMemo(() => {
    if (includeCurrentLang) {
      return availableLanguagesProp
    }
    return availableLanguagesProp.filter((x) => x.lang !== currentLang)
  }, [availableLanguagesProp, currentLang, includeCurrentLang])

  const i19nLanguages = useI18nArray(
    availableLanguages.map((availableLanguage) => availableLanguage.lang.toUpperCase())
  )

  if (!availableLanguages.length) {
    return null
  }

  let description = useI18n(descriptionKey)

  if (replaceKey) {
    description = description.replace(replaceKey, replaceValue)
  }

  const ContainerComp = showContainer ? Container : Fragment

  return (
    <ContainerComp>
      <Block marginTop={marginTop}>
        {description}
        {availableLanguages.map(({ lang, url }, index) => (
          <Link key={lang} toIncludesLang to={url}>
            {i19nLanguages[index]}
          </Link>
        ))}
      </Block>
    </ContainerComp>
  )
}

const Block = styled(({ marginTop, ...props }) => <div {...props} />)`
  background-color: ${({ theme: { colors } }) => styles.mixColor(colors.white, -0.1)};
  border-radius: 8px;
  border: 1px solid ${({ theme: { colors } }) => styles.mixColor(colors.white, -0.2)};
  color: ${({ theme: { colors } }) => colors.black};
  font-size: 1.2rem;
  padding: 1.5rem 1rem;
  ${({ marginTop }) => marginTop && 'margin-top: 3rem'};

  margin-left: -1rem;
  margin-right: -1rem;

  a {
    text-decoration: underline;
    color: ${({ theme: { colors } }) => colors.pink};

    &:focus,
    &:hover {
      color: ${({ theme: { colors } }) => colors.darkPink};
    }
  }
`

export default TranslatedOptions
