const DotList = ({ list }) =>
  list.filter(Boolean).reduce((acc, item, index) => {
    if (index > 0) {
      acc.push(' • ')
    }

    acc.push(item)
    return acc
  }, [])

DotList.defaultProps = {
  list: [],
}

export default DotList
